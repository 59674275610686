<template>
    <div style="margin-top: 38px;">
        <div class="about">
            <div class="about__center center">
                <h2 class="about__subtitle h2 aos-init aos-animate" data-aos="animation-scale-top" data-aos-duration="600">Privacy Policy</h2>
    
            </div>
            <vs-row style="width: 100%; gap: 30px;" justify="center">
                <vs-row direction="column" style="width:80%;">
                    <p style="text-align: left;">Informativa sul trattamento di dati personali ai sensi del Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio del 27 aprile 2016 (GDPR). In vigore dal 07/11/2022
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">PREMESSA</h2>
                    <p style="text-align: left;">
                        La presente informativa tiene conto di quanto indicato dal GDPR e dal Codice della Privacy (D. Lgs 30 giugno 2003 n. 196). Il documento è stato redatto anche in base alle Linee Guida del Garante Privacy (soprattutto le Linee Guida di contrasto allo spam emesse dl Garante Privacy il 4 luglio 2013). <br>

Titolare del Trattamento: RemindMe S.r.l., con sede legale in Corso giacomo Matteotti 42 10121 Torino (TO) e sede operativa in Strada del Mondino 13/D Castiglione Torinese (TO) 10090, P.I. 12783780013– R.E.A. Numero TO – 1316058. <br>

Sito al quale si riferisce la presente privacy policy: www.remindmevision.com (Sito). <br>

Il Titolare del Trattamento non ha nominato un DPO (Data Protection Officer). Pertanto, Lei può inviare qualsiasi richiesta di informazioni direttamente al Titolare del Trattamento.

                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">INFORMAZIONI GENERALI</h2>
                    <p style="text-align: left;">
                        Il presente documento descrive come il Titolare del Trattamento tratta i Suoi dati personali conferiti sul Sito.<br>
                        Di seguito vengono descritti i principali trattamenti dei Suoi dati personali. Viene in particolare spiegata la base giuridica del trattamento, se il conferimento è obbligatorio e le conseguenze del mancato conferimento di dati personali. Per descrivere al meglio i Suoi diritti, qualora necessario, abbiamo specificato se e quando un determinato trattamento di dati personali non viene effettuato.Sul Sito Lei ha la possibilità di inserire dati personali di terzi soggetti. In questo caso Lei garantisce di aver ottenuto il consenso d parte di questi soggetti all'inserimento di questi dati personali. Pertanto, Lei si impegna a manlevare e tenere indenne il Titolare del Trattamento da qualsiasi responsabilità.

                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">REGISTRAZIONE SUL SITO</h2>
                    <p style="text-align: left;">
                        Le informazioni e i dati richiesti in caso di registrazione verranno utilizzati per consentirLe sia di accedere all’area riservata del Sito, sia di usufruire dei servizi on line offerti dal Titolare del Trattamento agli utenti registrati. La base giuridica del trattamento è la necessità del Titolare del Trattamento di eseguire misure precontrattuali adottate su richiesta dell'interessato. Il conferimento dei dati è facoltativo. Tuttavia, il Suo eventuale rifiuto di conferire i dati comporterà l’impossibilità di registrarsi sul Sito.

                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">ACQUISTI SUL SITO</h2>
                    <p style="text-align: left;">
                        I Suoi dati personali verranno trattati per permetterLe di effettuare acquisti sul Sito. Nel caso di effettuazione di un ordine di acquisto online, per consentire la conclusione del contratto di acquisto e la corretta esecuzione delle operazioni connesse al medesimo (e, qualora necessario in base alla normativa di settore, per assolvere agli obblighi fiscali). La base giuridica del trattamento è l'obbligo del Titolare del Trattamento di eseguire il contratto con l'interessato oppure di adempiere ad obblighi di legge. A prescindere da quanto sopra (e quindi dal Suo consenso), il Titolare del Trattamento potrà trattare i suoi dati finalità di c.d. "soft-spam", disciplinato dall'art. 130 del Codice della Privacy. Questo significa che limitatamente alla email da Lei fornita nel contesto di un acquisto tramite il Sito, il Titolare del Trattamento tratterà l'email per consentire l’offerta diretta da parte di prodotti/servizi analoghi, sempre che Lei non si opponga a tale trattamento nelle modalità previste dalla presente informativa. La base giuridica del trattamento è il legittimo interesse del Titolare del Trattamento ad inviare questo tipo di comunicazioni. Questo legittimo interesse può ritenersi equivalente all'interesse dell'interessato a ricevere comunicazioni di "soft-spam". Il Titolare del Trattamento potrà inviare email per ricordare all'utente di completare un acquisto. La base giuridica di questo trattamento è il legittimo interesse del Titolare del Trattamento a inviare questa tipologia di comunicazioni.

                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">RISPONDERE ALLE SUE RICHIESTE</h2>
                    <p style="text-align: left;">
                        I Suoi dati verranno trattati per rispondere alle Sue richieste di informazioni. Il conferimento è facoltativo, ma il Suo rifiuto comporterà l’impossibilità per il Titolare del Trattamento di rispondere alle Sue domande. La base giuridica del trattamento è il legittimo interesse del Titolare del Trattamento a dare seguito alle richieste dell’utente. Questo legittimo interesse è equivalente all'interesse dell'utente a ricevere risposta alle comunicazioni inviate al Titolare del Trattamento.


                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">MARKETING GENERICO</h2>
                    <p style="text-align: left;">
                        Previo Suo consenso, il Titolare del Trattamento potrà trattare i dati personali da Lei conferiti al fine di inviarLe materiale pubblicitario e/o newsletter relativo a prodotti propri o di terzi. La base giuridica di questo trattamento è il Suo consenso. Il conferimento dei dati personali per questa finalità è meramente facoltativo. Il mancato assenso al trattamento dei dati per finalità di marketing comporterà l’impossibilità per Lei di ricevere materiale pubblicitario relativo a prodotti/servizi del Titolare del Trattamento e/o di terzi nonché l'impossibilità per il Titolare del Trattamento di svolgere indagini di mercato, anche dirette a valutare il grado di soddisfazione degli utenti, nonché di inviarLe newsletter. L'invio di queste comunicazioni avverrà alla e-mail da Lei conferita sul Sito.
    
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">PROFILAZIONE</h2>
                    <p style="text-align: left;">
                        Previo Suo consenso, il Titolare del Trattamento potrà trattare i Suoi dati personali per finalità di profilazione, ossia per l'analisi delle Sue scelte di consumo attraverso la rivelazione della tipologia e della frequenza degli acquisti da Lei effettuati, al fine di inviarLe materiale pubblicitario e/o newsletter relativi a prodotti propri o di terzi, di Suo specifico interesse. La base giuridica di questo trattamento è il Suo consenso. Il conferimento dei dati per questa finalità è meramente facoltativo. Il mancato assenso al trattamento dei Suoi dati personali per finalità di profilazione comporterà l’impossibilità per il Titolare del Trattamento di elaborare il Suo profilo commerciale, mediante la rilevazione delle Sue scelte e abitudini di acquisto nonché di inviarLe materiale pubblicitario, relativo a prodotti del Titolare del Trattamento e/o di terzi, di Suo specifico interesse. Queste comunicazioni verranno inviate alla e-mail da Lei conferita sul Sito.
    
                    </p>
                </vs-row>
                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">CESSIONE DEI DATI</h2>
                    <p style="text-align: left;">
                        Per l’invio di comunicazioni promozionali, previo Suo esplicito consenso, i Suoi dati personali potranno essere ceduti a "terzi". La base giuridica del trattamento è il Suo consenso. Il conferimento dei dati personali per questa finalità è meramente facoltativo. Il mancato assenso alla cessione comporterà l’impossibilità di cedere i Suoi dati personali a terzi per finalità di pubblicità.

                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">GEOLOCALIZZAZIONE</h2>
                    <p style="text-align: left;">
                        Il Sito non implementa strumenti di geolocalizzazione dell'indirizzo IP dell'utente.

                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">COMUNICAZIONE DEI DATI PERSONALI</h2>
                    <p style="text-align: left;">
                        Nell'ambito della propria ordinaria attività, il Titolare del Trattamento può comunicare i Suoi dati personali a determinate categorie di soggetti. All'articolo 2 Lei può trovare l'elenco dei soggetti ai quali il Titolare del Trattamento comunica i Suoi dati personali. Per agevolare la tutela dei Suoi diritti, l'articolo 2 può specificare in alcuni casi quando i Suoi dati non vengono comunicati a terzi.

La "comunicazione" a terzi del dato personale è diversa dalla "cessione" (disciplinata al punto che precede). Infatti, nella comunicazione il terzo al quale è trasmesso il dato può usarlo solo per le specifiche finalità descritte nel rapporto con il Titolare del Trattamento. Nella cessione, invece, il terzo diventa Titolare del Trattamento autonomo del dato personale. Inoltre, per cedere i Suoi dati personali a terzi è sempre richiesto il Suo consenso.

Fermo quanto precede, resta inteso che il Titolare del Trattamento potrà comunque utilizzare i Suoi dati personali per dare corretto adempimento agli obblighi previsti dalle leggi in vigore.

                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">INFORMATIVA PRIVACY SPECIFICA</h2>
                    <h2 class="stage" style="font-size:16px;">ART. 1 MODALITÀ DI TRATTAMENTO</h2>
                    <p style="text-align: left;">
                        1.1 Il trattamento dei Suoi dati personali sarà principalmente effettuato con l’ausilio di mezzi elettronici o comunque automatizzati, secondo le modalità e con gli strumenti idonei a garantirne la sicurezza e la riservatezza in conformità al GDPR. <br>

1.2 Le informazioni acquisite e le modalità del trattamento saranno pertinenti e non eccedenti rispetto alla tipologia dei servizi resi. I Suoi dati saranno altresì gestiti e protetti in ambienti informatici sicuri e adeguati alle circostanze.<br>

1.3 Tramite il Sito non vengono trattati "dati particolari". I dati particolari sono quelli che possono rivelare l'origine razziale ed etnica, le convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l'adesione a partiti, sindacati, associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale, lo stato di salute e la vita sessuale.<br>

1.4 Tramite il Sito non vengono trattati dati giudiziari.
                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">ART. 2 COMUNICAZIONE DEI DATI PERSONALI</h2>
                    <p style="text-align: left;">
                        Il Titolare del Trattamento può comunicare i Suoi dati personali a categorie determinate di soggetti. Di seguito vengono indicati i soggetti ai quali il Titolare del Trattamento si riserva di comunicare i Suoi dati:<br>

Il Titolare del Trattamento può comunicare i Suoi dati personali a tutti quei soggetti (ivi incluse le Pubbliche Autorità) che hanno accesso ai dati personali in forza di provvedimenti normativi o amministrativi. <br>
I Suoi dati personali possono essere comunicati anche a tutti quei soggetti pubblici e/o privati, persone fisiche e/o giuridiche (studi di consulenza legale, amministrativa e fiscale, Uffici Giudiziari, Camere di Commercio, Camere ed Uffici del Lavoro, ecc.), qualora la comunicazione risulti necessaria o funzionale al corretto adempimento degli obblighi derivanti dalla legge.<br>
Il Titolare del Trattamento si avvale di dipendenti e/o collaboratori a qualsiasi titolo. Per il corretto funzionamento del Sito il Titolare del Trattamento può comunicare i Suoi dati personali a questi dipendenti e/o collaboratori.<br>
Nella propria ordinaria attività di gestione del Sito, il Titolare del Trattamento si avvale di società, consulenti o professionisti incaricati dell’installazione, della manutenzione, dell’aggiornamento e, in generale, della gestione degli hardware e software del Titolare del Trattamento o di cui quest'ultimo si serve per l’erogazione dei propri servizi. Pertanto, solo con riferimento a queste finalità, i Suoi dati potranno essere trattati anche da questi soggetti.<br>
Il Titolare del Trattamento non si avvale di piattaforme CRM (società che svolgono in particolare l'attività di inviare comunicazioni automatizzate agli utenti). Pertanto, i Suoi dati personali non vengono comunicati a queste società.<br>
Il Titolare del Trattamento non si avvale di società esterne per prestare il servizio di customer care.<br>
Il Titolare si riserva la facoltà di modificare il sopra indicato elenco in base alla propria ordinaria operatività. Pertanto, Lei è invitato ad accedere con regolarità alla presente informativa per controllare a quali soggetti il Titolare del Trattamento comunica i Suoi dati personali.

                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">ART. 3 CONSERVAZIONE DEI DATI PERSONALI</h2>
                    <p style="text-align: left;">
                        3.1 Il presente articolo descrive per quanto tempo il Titolare del Trattamento si riserva il diritto di conservare i Suoi dati personali.<br>

Per finalità di marketing, i dati personali saranno conservati fino alla eventuale revoca del consenso. Per gli utenti inattivi, i dati personali verranno cancellati dopo un anno dall'invio dell'ultima email eventualmente visionata.<br>
Come previsto dall'articolo 2220 del codice civile, le fatture, così come tutte le scritture contabili in generale, vengono conservate per un periodo minimo di dieci anni dalla data di registrazione, così da poter essere presentate in caso di controllo.<br>
3.2 Fermo quanto previsto all'articolo 3.1, il Titolare del Trattamento può conservare i Suoi dati personali per il tempo richiesto da normative specifiche, come di volta in volta modificate.


                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">ART. 4 TRASFERIMENTO DEI DATI PERSONALI</h2>
                    <p style="text-align: left;">
                        4.1 Il Titolare del Trattamento ha sede all'interno dell'Unione europea. Pertanto, il trattamento dei Suoi dati è sicuro dal punto di vista normativo in quanto disciplinato dal GDPR. Qualora il trasferimento dei Suoi dati personali avvenga in un Paese extra-UE e per il quale la Commissione europea ha espresso un giudizio di adeguatezza, il trasferimento si ritiene in ogni caso sicuro dal punto di vista normativo. Il presente articolo 4.1 indica di volta in volta i Paesi nei quali i Suoi dati personali possono essere eventualmente trasferiti e dove la Commissione europea ha espresso un giudizio di adeguatezza.<br>

Si invita pertanto l'utente ad accedere con regolarità al presente articolo per verificare se il trasferimento dei Suoi dati personali avviene in un Paese con queste caratteristiche.
4.2 Fermo quanto indicato all'articolo 4.1, i Suoi dati possono essere trasferiti anche in Paesi extra-UE e per i quali la Commissione europea non ha espresso un giudizio di adeguatezza. Lei è pertanto invitato a visionare con regolarità il presente articolo 4.2 per appurare in quali di questi Paesi i Suoi dati sono eventualmente trasferiti.<br>

4.3 In questo articolo il Titolare del Trattamento indica i Paesi presso i quali eventualmente dirige in modo specifico la propria attività. Questa circostanza può implicare l'applicazione della normativa del Paese di riferimento, unitamente a quella del GDPR.<br>

Su richiesta dell'utente, il Titolare del Trattamento applicherà al trattamento dei dati personali la normativa eventualmente più favorevole prevista dalla legislazione nazionale dell'utente stesso.

                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">ART. 5. DIRITTI DELL'INTERESSATO</h2>
                    <p style="text-align: left;">
                        Ai sensi dell’art. 13 del Regolamento Privacy, il Titolare del Trattamento La informa che Lei ha diritto di:<br>

chiedere al Titolare del Trattamento l’accesso ai Suoi dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che La riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati<br>
revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca<br>
proporre reclamo a un’autorità di controllo (es.: il Garante per la protezione dei dati personali).<br>
I diritti di cui sopra potranno essere esercitati con richiesta rivolta senza formalità ai contatti indicati in Premessa.

                    </p>
                </vs-row>

                                <vs-row direction="column" style="width:80%;">
                    <h2 class="stage" style="font-size:16px;">ART. 6. MODIFICHE E MISCELLANEA

</h2>
                    <p style="text-align: left;">
                        Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente informativa in qualsiasi momento, dandone idonea pubblicità agli utenti del Sito e garantendo in ogni caso una adeguata ed analoga protezione dei dati personali. Al fine di visionare eventuali modifiche, Lei è invitato a consultare con regolarità la presente informativa. In caso di modifiche sostanziali alla presente informativa privacy, il Titolare del Trattamento ne potrà dare comunicazione anche tramite email.

                    </p>
                </vs-row>
                
            </vs-row>
        </div>
    </div>
    </template>
    
    <script>
    import AOS from 'aos';
    export default ({
        mounted() {
            AOS.init();
        },
    
    })
    </script>
    
    <style scoped>
    ul {
        list-style-type:circle;
        margin-left: 19px;
        margin-top: 10px;
    }
    </style>
